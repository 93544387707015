.privacy-notice{
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0;
    opacity: 0.96;
    background: #002d73;
    color: white;
    padding: 28px 50px;
    align-items: center;
    flex-direction: column;
    z-index: 100;
    .privacy-close{
        position: absolute;
        top: 0;
        right: 0;
        padding: 17px 13px;
        cursor: pointer;
    }
    .header{
        @include tiempos(medium);
        margin-bottom: 15px;
    }
    .header, .content{
        text-align: center;
        display: block;
    }
    .content{
        max-width: 715px;
    }
    a{
        color: white;
        @include bentonsans(medium);
        &:hover{
            color: white;
        }
    }
}