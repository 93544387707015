.page-content{
    &.page-hdr-title{ padding-bottom: 15px; padding-top:35px; padding-left: 30px;
        h1{display: inline-block; font-size: px2rem(18); color: $dark_blue; @include tiempos; text-align: left; text-transform: capitalize;
            @include breakpoint(medium) { font-size: px2rem(30); }
        }
        .select-menu-primary{ display: inline-block; width: auto;
            .title{text-transform: capitalize; font-size: px2rem(30); font-weight: bold; }
        }
    }
    &.patronInfo{
        .tabsInfo{ z-index: 1;}
    }
    &.statsInfo{ padding: 0px 30px;
        .selectStatsMenu{
            .title{ font-size: px2rem(14); text-transform: capitalize;
                @include breakpoint(medium) { font-size: px2rem(18); }
            }
        }
    }
}
article {
    padding: 20px 0px; max-width: $max-content-width; margin: 0px auto;
    overflow: hidden; //amb - 102318 gallery styling

    .header{ color: $dark_blue; margin-bottom: 15px; font-size: px2rem(20); @include tiempos;
        .count { font-size: px2rem(16); color: $dark_blue; @include bentonsans(medium);}
        .icon-camera{ color: $dark_blue; font-size: px2rem(18); margin: 0px 10px;}
    }
	.image-wrapper{
        max-width: 1440px;
        max-height: 810px;
        margin: 0px auto;
        background-color: #f2f2f1;
        img{ 
            display: block; 
            margin-left: 
            auto; margin-right: auto;
            @include breakpoint(large) { width: 1170px; }
            @include breakpoint(xlarge) { width: 1170px; } 
            width: 100%;
            height: 100%;                          
        }
    }
    .text{
        &.empty{ padding: 0px!important;
           p{ padding: 0px;} 
        }
        &.centered{
            @include clearfix; margin: auto auto ; width: 92%; padding-top: 20px;
            @include breakpoint(medium){ max-width: $article-max-content; }
        }
        &.extra_spacing{
            // p{ padding-bottom: 1em; padding-top: 1em;}
            ul, ol { padding-left:4em; color: $gray_2; line-height: 1.8em; padding-bottom: 1.8em;
                ul, ol { padding-bottom: 0; }
            }
        }
        &.gray_hr{ border-bottom: 1px solid rgba(0,0,0,0.1); margin-bottom: 50px; }

        &.multi-col{
            @include breakpoint(medium){
                width: 45%; margin-left: 2%; display: inline-block; vertical-align: top;
            }
        }

        &.half{ width: 75%; margin-left: 5%; display: inline-block;
            &.multi-col{  width: 100%; vertical-align: top;
                @include breakpoint(small){ width: 33%;}
                @include breakpoint(medium){ width: 35%; margin-left: 2%;}
            }
        }
        &.indent{ margin-left: 5%!important;}

        h2{ font-size: px2rem(22);color: $dark_blue; @include tiempos; text-align: left;
            @include breakpoint(medium){ font-size: px2rem(30); font-weight: bold;}
        }
        h3{ font-size: px2rem(18); line-height: 1.09em; text-transform: capitalize; @include tiempos(medium);color:$dark_blue;
            @include breakpoint(medium){ font-size: px2rem(24); }
        }
        h4{ font-size: px2rem(16);
            a{ text-decoration: none; color: $dark_blue;}
        }
        h5{ font-size: px2rem(12); padding-bottom:10px; @include bentonsans(medium); color: $dark_blue;
            a{ text-decoration: none; color: $dark_blue;}
            &.chevron { position: relative;}
        }
        p{ font-size: px2rem(16); line-height: px2rem(26); padding-bottom: 25px;
            &.attention{ color: $bright_red;}
        }
        .video-container + .textWrapContent { font-size: px2rem(16); line-height: px2rem(26); padding-top: 25px;
            &.attention{ color: $bright_red;}
        }        
        ul{
            margin-left: 20px;
            padding-bottom: 25px;
        }
        ul, ol{
            &.checkmarks{ list-style: none; list-style-type: none!important; padding-left: 5px;
                li:before{ content: '\2713';color: $dark_blue; padding-right: 10px; }
            }
            &.xmarks{ list-style: none; list-style-type: none!important; padding-left: 5px;
                li:before{ content: '\2715';color: $dark_red;padding-right: 10px; }
            }
        }
        .print_link {
            text-decoration: none;
            color: $dark_blue;
            font-weight: 500;
            cursor: pointer;
        }
    }
    .articleInfo{
        .author_date{ 
            //padding-bottom: 1.8em;
            color: $gray_6; 
            font-size: px2rem(12); 
            margin-top: 5px;
            @include breakpoint(large){ 
                font-size: px2rem(14); 
            }
            .date {
                display: inline-block;
            }
        }

        .actions{ @include bentonsans('bold'); text-transform: uppercase; color: $gray; float: right; position: relative;
            @include clearfix;
            .share{ 
                display: block; 
                text-align:left; 
                font-size: 22px; 
                color: $gray_6;
                position: absolute;
                right: 0px;
                top: -25px;
                @include breakpoint(xsmall) {
                    top: -55px;
                }                 
                @include breakpoint(small) {
                    top: -55px;
                }
                @include breakpoint(medium) {
                    // top: -70px; removing #1593: misaligned -amb111120
                }               
                 
            }
        }

        .title{ margin: 0 0 0.25em 0; @include tiempos; font-size: px2rem(22); color:$dark_blue; margin-left: 0px;
            @include breakpoint(medium){ font-size: px2rem(30); }
        }
    }
    &.health_and_safety{
        .half{
            p{ padding-bottom: 0.3em; }
        }
    }   
}

.webview article {
    padding-top: 0;
}
#terms-agree {

    .first_name{
        border: none;
        border-bottom: 2px solid lightgrey;
        padding: 5px 0px;
        outline: none;
        margin-top:10px;
    }
    label{
        font-size: 12px;
        color: grey;
        padding: 10px 0px;
    }
}
.terms_name{
    margin-bottom:10px;
}


.print-content {
    display:none;
}
.accept-content {
    display:none;
}
.accept-page {
    .accept-content {
        display:block;
    }
}
.print-page {
    .print-content {
        display:block;
    }
}
.policy-buttons{
    text-align:center;
    margin-bottom: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .button{
        border: 1px solid #002d73!important;
        color: #002d73!important;
    }
}


