//TODO: Re-visit these margins when we figure all that stuff out. I have commented out all references to margin-width. - RLL
//TODO: Will revisit later.  I have commented out section.hero-wrap, but Robin uncommented what she needs. Trying to elimanate complicated hero styles - AWT
.grecaptcha-badge { visibility: hidden; }

.dcp-site {
	color: $body_text;
}

section.main {
    margin-left: auto;
    margin-right: auto;

    &.no-maxwidth {
        max-width: 100%;
    }
}

section.hero-wrap {
    position: relative; width: 100%; height: calc(100% - #{$mobile_header_height}); z-index: z($elements, hero);

    &.player { height: auto;
        .hero-content { height: auto; width: 100%;
            .contentWrapper { position: absolute; width: 100%; top: 0; left: 0; }
        }
    }
}

@include breakpoint(medium) {
    section.hero-wrap {
        margin-top: 0; height: calc(100% - #{$header_height});
    }
}

.hero-content { position: relative; height: 100%; }

.share { cursor: pointer; }

section.section-title-wrapper, section.page-title {
    display:flex;
    width:100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding:0px $xs-content-margin;
    color: $dark_blue;
    @include tiempos(medium);
    text-transform: capitalize;

    @include breakpoint(small) {
        padding:0px $sm-content-margin;
    }
    @include breakpoint(medium) {
        padding:0px $lg-content-margin;
    }

    h1 {
        font-size: px2rem(28);
        font-weight: normal;
        padding-top: 28px;
        padding-bottom: 28px;
    }
    h2 {
        font-size: px2rem(24);
        line-height: 29px;
        margin-top:30px;
        @include tiempos(medium);

        @include breakpoint(medium) {
            font-size: px2rem(30);
            line-height: 56px;
            margin-top:0px;
        }
        @include breakpoint(large) {
            font-size: px2rem(30);
        }
    }
}

section.page-grid {
    h3 {
        font-size: px2rem(20);
        line-height: 40px;
        margin-bottom:14px;
        text-transform: capitalize;
        color: $dark_blue;

        @include tiempos(medium);

        @include breakpoint(medium) {
            font-size: px2rem(24);
            margin-bottom:26px;

        }
        @include breakpoint(large) {
            font-size: px2rem(24);
        }
    }
    h4 {
        color: $dark_blue;
        @include tiempos(medium);
        line-height: 40px;
    }
}


section.page-grid {
    > h3 {
        color: $dark_blue;
        @include tiempos();
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: px2rem(18);

        @include breakpoint(medium) {
            font-size: px2rem(30);
        }
        @include breakpoint(large) {
            font-size: px2rem(30);
        }
    }
}

section.section-title-wrapper {
    display:flex;
    width:100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding:0px $sm-content-margin;
    padding-top:35px;
    padding-bottom:15px;
    max-width:992px;
    width:92%;
    margin:0px auto;
    h3,h1 {
        color: $dark_blue;
        @include tiempos();
        font-size: px2rem(18);

		@include breakpoint(medium) {
			font-size: px2rem(30);
		}
    }
}

section.header { @include clearfix; }

section.header-content { position: relative; padding: 15px 1.69492% 0; margin: 0 1.69492%; @include clearfix; }

.title-area { position: relative; @include clearfix;
    .back_link { position: relative; /*height: 35px;*/ height: 20px;
        a { @include bentonsans(medium); font-size: px2rem(12px); color: $dark_blue; text-decoration: none; padding-left: 15px; position: relative;
            &:before { position: absolute; top: 2px; left: 0px; @include border-arrow(6px, 10px,$dark_blue, left); cursor: pointer; }
        }
        &.left { float: left; margin-left: 1.69492%; }
    }
    .share{ display: block; text-align:left; color: $black; text-indent: -9999px; position: absolute; right: 1.69492%; top: 2px; z-index: z($title_bar, share);
        @include resizable_icon('/assets/images/misc/share_android.png'); @include centered_icon_size(12px);
    }
}

@include breakpoint(medium) {
    section.header-content { padding-top: 0px; }

    .title-area {
        &.mobile_tablet { display: none; }
        .back_link { margin-top: 15px;
            a { font-size: px2rem(14px);
                &:before { top: 3px; }
            }
            &.left { margin-left: 0; }
        }
        .share { right: 0; margin-top: 7px; @include centered_icon_size(20px);}
    }
}

@include breakpoint(xlarge){
    article,
    #heroWrapper,
    section.summary-sb .mini-lb,
    .hero-content .header,
    section.header-content,
    footer.hole_detail,
    section.hero-wrap.player,
    section.hero-wrap.info-pages,
    #whatsNew, #heroContainer
    { max-width: $max-content-width; margin: 0px auto; }
}

.text { clear: both; padding: 0; @include bentonsans; @include clearfix;
    p { padding-bottom: 0.3em;}
    em { font-style: italic; }
    strong { @include bentonsans(bold); }
    section.full-width + & { padding-top: 0.5em; }
    a { @include bentonsans(medium); color: $gray_3;
      &.linktag{
        text-decoration:none; color: $dark_blue; text-transform: capitalize;
        @include bentonsans(medium);
        &.chevron { position: relative; }
      }
      &.link_txt_small { font-size: 0.75em;
        @include breakpoint (medium){ font-size: 0.9em;}
      }
    }
    h2 { font-size: 1.833em; line-height: 1.818em; color:$gray_2; padding-bottom:0.3em + 0.3em; padding-top: 0.3em; }
    h3 { color:$gray_2; padding-bottom:0.8em; line-height:1.8em; @include bentonsans(bold);}

    &.extra_spacing{
        ul, ol { padding-left:2em; color:$gray_2; line-height: 1.8em; padding-bottom: 1.8em;
            ul, ol { padding-bottom: 0; }
        }
        p {
            padding-bottom:1.8em;
            line-height:1.8em;
            color:$gray_2;
            &.error_msg{
              color:#ef3340;
            }
            &.h_subtxt{text-align: center; font-weight:bold; color: #333333; font-size: 1.125em;text-transform: uppercase;}
            &.p_font1{font-size: 1.500em; font-weight: bold; padding-top:10px;}
            &.p_font2{font-size: 1.000em;}
            &.p_4font3{}
        }
        section.full-width + & { padding-top: 1.8em; }
        hr { margin-bottom:1.8em; }
        &.top_spacing{
            padding-top: 40px;
        }
  
      }

  }

.details-title{ font-size: 12px; width: 100%; margin-bottom: 15px;
    span { font-size: px2rem(18px); color: $dark_blue; @include tiempos;
        @include breakpoint(small) { display: inline-block; margin-right: 12px; }
        @include breakpoint(medium) { font-size: px2rem(24px); }
        @include breakpoint(large) { font-size: px2rem(30px); }
    }
}

.details-subtitle{ font-size: px2rem(12px); color: $gray_b; @include bentonsans(book); margin-top: 2px;
    @include breakpoint(small) { display: inline-block; margin-top: 0; }
    @include breakpoint(medium) { font-size: px2rem(14px); }
    @include breakpoint(large) { font-size: px2rem(16px); }
}

.chevron {
    position: relative;

    &.left { padding-left: 8px;
        @include breakpoint(medium) { padding-left: 15px; }
        &:before { content: " "; position: absolute; left:0; top: 0; bottom: 0; margin-top: auto; margin-bottom: auto; @include chevron-arrow(7px, 7px, 2px, $black, left); }
    }
    &.right { padding-right: 0; width: auto;
        &:after { content: " "; position: absolute; top: 0; bottom: 0; margin-top: auto; margin-bottom: auto; @include chevron-arrow(7px, 7px, 2px, $black, right); }
    }
}

.site-alert {
    height: 40px;
    background: #00AC69;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    line-height: 40px;

    a {
        color: #ffffff;
        text-decoration: underline;
        font-weight: 500;
        margin-left: 5px;
    }
    .scrolled & {
        display:none;
    }
}

.notification {
    position: fixed;
    top:40px;
    left: 40px;
    width: 280px;
    border-top: 3px solid $bright_red;
    z-index: 30;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px;
    font-size: 12px;

    .close {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 10px;
        height: 10px;
        &:before, &:after {
            content: ' ';
            height: 10px;
            width: 1px;
            background: #888B8D;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }
        &:after {
            right:0;
            transform: rotate(-45deg);
        }
    }

    h5 {
        text-transform: uppercase;
        font-size: 11px;
        color: $bright_red;
    }

    h6 {
        font-family: Tiempos, 'Times New Roman', Times, serif;
        font-size: 16px;
        color: $dark_blue;
        padding: 5px 0 10px 0;
    }

    a {
        display: block;
        padding-top: 10px;
        text-decoration: none;
        font-weight: 500;
    }
}

.centered_icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background:transparent no-repeat center center;
    @include centered_icon_size(45px);

    @include breakpoint(small) { @include centered_icon_size(54px); }
    @include breakpoint(xlarge) { @include centered_icon_size(70px); }

    &.play { @include resizable_icon('/assets/images/now/btn_play.png'); }
    &.grid { @include resizable_icon('/assets/images/now/btn_grid.png'); }
    &.radio { @include resizable_icon('/assets/images/misc/radio_notplaying.png'); }
}

a.skip-main {
    left:-999px;
    position:absolute;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;
}

a.skip-main:focus, a.skip-main:active {
    color: $dark_blue;
    background-color:#fff;
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow:auto;
    margin: 10px 35%;
    padding:5px;
    border-radius: 15px;
    border:4px solid $dark_blue;
    text-align:center;
    font-size:1.2em;
    z-index:999;
}

body.using-mouse :focus {
  outline: none;
}
*:focus {
  outline: solid 2px $dark_blue;
}

.grecaptcha-badge { visibility: hidden; }

// for accessibility
.screen-reader-only {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(1px 1px 1px 1px); // IE 6 and 7
    clip: rect(1px,1px,1px,1px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
    overflow: hidden !important;
}

.form-content .termsAgree {
    a {
        color: $dark_blue !important;
    }
}

// webview accessibility
// disable outline for webviews
.webview {
	#main {
		outline: none;
	}
}

.redText, .error_msg {
    color:#ef3340;
    a {
        color: #ef3340;
    }
}

body {
  #eventLogo {
      background: url(/assets/images/nav/dcp_logo.webp) no-repeat; 
      content: '';
      width: 150px;
      height: auto;
      aspect-ratio: 78/19;
      display: block;
      background-size: contain;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
  }

  &.print {
      section#main{padding:10px 20px; margin: 0px auto 30px}
      section.hero-section {display:none;}
      section.page-title { display:none; padding: initial; margin: 0px auto ;}
      section.page-content {min-height: auto; max-width: 1280px;}
      section.page-grid {margin-bottom:0px; padding: initial; margin: 0px auto; width: 100%;}
      section.page-grid.cols-1 > .page-grid-item {
          margin-top:0px;
      }
      article{padding: initial; width: 100%; max-width: 992px;}
      article .text.centered {margin: 0px auto; width: 100%;}
      article .policy-buttons{display: none;}
      section.section-title-wrapper {
        padding: initial;
        margin: 0px auto ;
          h2 {font-size: 1.5rem;}
          width: 100%;
      }
      .print-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          max-width:992px;
          width:100%;
          margin: 0px auto 20px;
          a {visibility: hidden; display:block;}
      }
     
      a {display:none}
      .page-actions {display:none}

      @media print {
          //padding:20px 0px;
         // * {font-size: px2rem(12)}
         .print-header{
          // visibility: hidden;
          //display: none;
         } 

         .printLogo{
          width:156px;
          display: none;
          //margin: 0px auto 
         //  margin-left:30px;
       }
      }
  }
}