
.form-content{
    .validation, .form-title, .termsAgree a{
        color: #002d73!important;;
    }
    .error-field, .error-item, .icon-error__i{
        //color: #002d73
    }
    .select-box {
        .react-select-container, .react-select__value-container, .react-select__single-value {
            color: #002d73!important;

        } 
        .react-select__control--is-focused {
            border-bottom: 2px solid #002d73 !important;

        }
        .select_down_arrow {
            &:after {
                border-top: 5px solid #002d73 !important;
            }
            
        }
    }
    label, 
    .float-label input, 
    .fixed-label input:not(#react-select-2-input), 
    .readonly-label input, 
    .float-label input:focus, 
    .fixed-label input:not(#react-select-2-input):focus, 
    .fixed-label input:not(#react-select-2-input)::placeholder, 
    .readonly-label input:focus {
        //border-bottom: #002d73!important;
        color: #002d73!important;
        caret-color: #002d73!important;

        &:focus {
            border-bottom: 2px solid #002d73 !important;
        }
    }
    
    #VerifyIdentityForm {
        .options {
            .selectVerificationMethod, .radio-option{
                i {color: #002d73;}
              }

            span {
                .message-content{
                    color: #002d73;
                }
            }
        }
        
    }
.page-links{
    .forgot-password-link a{
        color:#002d73;
    }
   
}
.page-actions{
    .button{
        color:#002d73!important;
        border: 1px solid #002d73;
        &.disabled{
            color:#C8C9C7!important;
            border: 1px solid #C8C9C7;
        }
    }

}

.checkbox-option label{
    .message-content {color:$black}
    &:after{
        background-color:#002d73;
    }
    &:hover {
        &:before {
            box-shadow: inset 0 0 0 1px #002d73;
        }
    }
}

}
.page-actions{
    a.action-link{
        color:#002d73!important;

        &:focus {   
            border:2px solid #002d73;
        }
    }
    .button{
        color:#002d73!important;
        border: 1px solid #002d73;
        
        &:focus {
            outline: solid 2px #002d73;
        }
        &.disabled {
            color: $disabled_color !important;
            border: 1px solid $disabled_color;
        }
    }
}
.account-info{
    table{
        td{
            color:#002d73!important;
        }
    }
    .header, .edit-account-info {
        color:#002d73 !important;
    }
}
.ticket-application table th.highlight{
    background-color:#002d73!important;
}

input.button.submit, div.button {
    &:focus {
        outline: solid 2px #002d73;
    }
}