
header {
    position: fixed;
    background-color: #FFFFFF;
    height: $header-height;
    top: 0;
    z-index: 21;
    width: 100%;
    &.alert {
        height: $header-height-alert;
    }
    &.scrolled {
        height:$mobile-nav-height;
        @include breakpoint(medium) {
            height:$nav-height;
        }
    }
}

body.noMiniLb {
    header {
        height: $mobile-nav-height;

        @include breakpoint(small) {
            height: $header;
        }
        @include breakpoint(medium) {
            height:$header-lg;
        }
        
        &.alert {
            height: $header-alert;

            // section.subheader {
            //     top: $global-alert-height;
            // }

            @include breakpoint(medium) {
                height:$header-alert-lg;
            }

            &.scrolled {
                height:$mobile-nav-height;
                @include breakpoint(medium) {
                    height:$nav-height;
                }
            }
        }
    }
}
