.review-page{
    //@include tiempos();
    .hero-section{
        padding:0px;
    }
    .text{
        .section_title{
            color: #0D6747;
            font-weight:normal;
        }
    }
    .ticket-info{
        font-weight: normal;
        margin-top:30px;

        .header{
            @include bentonsans(medium);
            color: $dark_blue;
            margin-bottom: 0px;
            font-size: px2rem(20);
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            div:nth-child(1){
                width:30%;
                float:left;
                
            }
            h3 {
                margin-bottom: 0;
            }
            .edit-account-info{
                @include bentonsans(medium);
                font-size: px2rem(14);
                color:$dark_blue;
    
                a {
                    text-decoration: none;
                }
                p{
                    font-size:px2rem(12);
                    color:$dark_blue;
                    
                }
                @include breakpoint(small) {
                    font-size:px2rem(16);
                }
            }
        }    
    }
    .policy-buttons{
        margin-top:10%;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            width: 30%;
            padding: 30px;
            font-size: px2rem(14);
            font-weight: normal;
            text-decoration: none;
            color: $dark_blue;

        }
    }
    .total {
        margin-top:20px;
        @include bentonsans(medium);
        font-size: px2rem(18);
        text-align: right;
    }
}
.printLogo{
    display:none;
}

@media print{

    * { overflow: visible !important;} 
    html, body {
        border: 1px solid white;
        height: calc(99% - 233px) !important;
        page-break-after: avoid;
        page-break-before: avoid;
        // * {
        //     font-size: px2rem(12);
        // }
     }
    .page-actions, .hero, #dcp-header, #bottom, .edit-account-info, .backToTop, .policy-buttons{
        display:none!important;
        visibility:none!important;
    }
    .printLogo{
       display:block;
       width:156px;
       margin-left:30px;
    }

    #masters-header{
        display:none;
    }
    body { 
        //margin: 1.6cm; 
        font-size:80%;
    }
    .ticket-info .header{
        //margin-top:-50px;
    }
     .ticket-info .ticket-application:nth-child(1){
        margin-top:50px;
     }

    #print-area{
        // position:absolute;
        // left:2px;
        // top:0;
        .page-title{
            display:none;
            visibility:none;
        }
        .review-page{
            // transform: scale(0.95); // this shrinks the page
            // transform-origin: 0 0;
            // line-height: 1.1em;
            // height:auto;
            .page-grid-item{
                margin-top:-10px;
            }
            .ticket-info{
               // margin-top:25px;
                .ticket-title{
                    margin-top:10px;
                }
                .ticket-application:nth-child(1){
                    margin-top:-30px;
                }
                .header{
                    h3{
                        margin-bottom:0px!important;
                    }
                }

            }
            
        }
    }

    .page-content.backToTopWrapper {display:none;}
    
    .ticket-application table td, .ticket-application table th{
        padding:0px;
        &.highlight {
            print-color-adjust: exact;
        }
    }

    .calendarContainer {
        display:none;
    }
    .jsx-parser {
        p {line-height: 1.2em;}
    }
}