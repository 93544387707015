.under-construction-page, 
.under-construction-content{
    width: 100%;
    height: 100%;
    display: flex; 
}
.under-construction-content {
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
.under-construction-img {
    position: fixed;
    img {
        width:100vw;
        height:auto;

        @include breakpoint(medium) {
            width: auto;
            height:auto;
        }
    }
}
.under-construction-message {
    width: 50%;
    position: relative;
    text-align: center;
    font-style: normal;
    font-weight: 500;

    @include breakpoint(medium) {
        width:70%;
    }

    .message-header{
        @include tiempos;
        position:relative;
        font-size: px2rem(21);
        color: $white;

        @include breakpoint(medium) {
            font-size: px2rem(38);
        }
    }
    .message-body{
        position: relative;
        //top: -20px;
        color: #FCE300;
        font-size: px2rem(12);
        text-transform: uppercase;

        @include breakpoint(medium) {
            font-size: px2rem(20);
        }
    }
    .message-content {
        line-height: normal;
        p {
            margin:0px;
            line-height: normal;
            //line-height: 2.0px;
        }
    }
}

.cookie-page {
    .under-construction-img {
        &.small {display:block}
        &.large {display:none}
        @include breakpoint(medium) {
            &.small {display: none;}
            &.large {display: block;}
        }
    }
}