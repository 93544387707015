//Mobile Navigation styles
body.js-nav { overflow: hidden; position: fixed; width:100vw; height: 100vh; /* height: 100%;*/ } // prevent body content from being scrollable
.js-nav {
    #masters-header {
      &.scrolled {
        section.nav {
          section.nav-wrap {
            .mainmenu {
              height:100vh
            }
          }
        }
      }
    }

	@include breakpoint(xsmall){
		.alert {
		  .mobile-link {
			   &.active {
          //position: fixed;
          position:relative;
		 		  top: 0;
			   }
		   }
		}
	}
	@include breakpoint(medium){
		.alert {
		  .mobile-link {
			   &.active {
				  position: inherit;
		 		  top: auto;
			   }
		   }
		}
	}
}


  // no Mini LB
body.noMiniLb {
  // section.nav.old {
  //     top: 0;
  // }

  .outer-wrap {
    &.track {
      header.alert {
        height: $header-alert;
      }
      section.nav.old {
        @include breakpoint(medium) {
          top: $global-alert-height !important;
        }
      }
    }
  }
}


section.subheader {
  position: relative; 
  height: $mobile_nav_height; 
  width: 100%; 
  background-color: $white; 
  border-bottom: 1px solid $gray_d; 
  z-index: z($elements, header);
  display:flex;
  flex-direction: row;
  align-items: center;
  padding:0px $sm-content-margin;
  
  .iconLBLink {
    font-size:px2rem(16);
    color:$dark_green;
    margin-left:18px;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      //text-indent: -9999px;
    }
    .visually-hidden {
      width:0px;
      height:0px;
      visibility: hidden;
    }
  }

  #eventLogo { 
    background: url(/assets/images/nav/dcp_logo.webp) no-repeat 0px 13px; 
    background-size: 88px 23px; 
    @include wh(88px,$mobile_nav_height); 
    //left: 50%; 
    //transform: translate(-50%,0);

      flex: 0 1 auto;
      position: absolute;             /* new */
      left: 50%;
      top:0px;
      transform: translateX(-50%);

    a { 
      display: block;  
      @include wh(88px,$mobile_nav_height); 
      text-indent: -9999px; 
    }
  }

  .hamburger-nav-login {
    display: block;
    background-color: $gray_f;

    .nav-login {
      display: flex;

      &.not-logged-in {
        justify-content: space-evenly;
        align-items: center;

        a {
          text-align: center;
        }
        .login-divider {
          color: $gray_d;
          font-size: px2rem(24);
        }
      }
      &.logged-in {
        flex-direction: column;
        justify-content: flex-start;

        ul {
          padding-top: 0px;
          width: 100%;
          background-color: $gray_f; 
        }
        .name-avatar, > ul li {
          line-height: 60px;
          width: calc(100% - (4.16% * 2));
          height: 100%;
          border-top: 1px solid $gray_d;
          margin: 0px auto;
        }
        .name-avatar {
          margin-top: 15px !important;
          border-top: 0 !important;
        }
        > ul li {
          font-size: px2rem(14);

          &:first-child {
            border-top: 0;
          }
        }
      }
    }
  }

  .subheader-nav-login {
    display: none;
  }

  .subheader-right {
    margin-left:auto;
    margin-right:0px;
    display: flex; 
    align-items: center; 
    justify-content: flex-end;
    height: 100%;

    .nav-login {
      margin-right: 36px;
      
      &.not-logged-in {
        position: relative;
        top: 8px;
      }
      &.logged-in {
        height: 100%;
      }
      .login-divider {
        color: $gray_d;
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }

  .nav-login {
    font-size: px2rem(16);

    a {
      text-decoration: none;
    }
    .name-avatar {
      font-size: px2rem(21);
      color: $dark_green;
      @include tiempos(medium);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px !important;
      // border-top: 1px solid $gray_d;

      .avatar {
        width: 42px;
        height: 42px;
      }
    }
    li {
      a {
        &.separated {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        }
        .small-right {
          font-size: px2rem(12);
          @include bentonsans(medium);
          color: $dark_blue;

          i {
            font-size: px2rem(8);
          }
        }
      }
      &.logout {
        text-align: center;
        cursor: pointer;

        a {
          justify-content: center;

          @include breakpoint(medium) {
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .sponsors { 
    // margin-left:auto;
    // margin-right:0px;
    // display: flex; 
    // align-items: center; 
    // justify-content: flex-end;
    img { display: inline-block; }

    .ibm {
        img { width: 34px; height: 12px; }
    }
    .att {
        img { width: 49px; height: 16px; }
    }
    .mb {
        img { width: 20px; height: 20px; }
    }
    .ibm, .att, .mb{
        display: none;
        &.show{display: block;}
    }
  }

  @include breakpoint(medium) {
    height: $nav_height; padding: 0 $lg-content-margin; border-bottom: 0;

    .iconLBLink {
      display:none;
    }

    #eventLogo { z-index: z($elements, header); 
      @include wh(156px,$nav_height); 
      background-size: 100% auto; 
      background-position: 0 16px; 
      transform: none;
      order:0;
      position: relative;
      left:auto;
      a { height: $nav_height; width: 156px; }
    }

    .hamburger-nav-login {
      display: none;
    }

    .subheader-nav-login {
      display: flex;
      align-items: center; 
    }

    .nav-login {
      display: block;

      .moreLink {
        position: relative;
        top: 8px;
        color: $selected_color;
        cursor:pointer;
        
        &:hover { color: $selected_color; }
        &.greeting {
          display: flex;
          align-items: center;
        }
        .avatar {
          margin-left: 9px;
          width: 32px;
          height: 32px;
          // background-size: 32px 32px;
        }
      }

      // @include breakpoint(medium) {
      //   .submenu {
      //     width: 375px;
      //   }
      // }
    }

    .sponsors { z-index: z($elements, header); width: 61px; position: relative; top: 8px;
      .ibm {
        img { width: 50px; height: 18px; }
      }
      .att {
        img { width: 61px; height: 20px; }
      }
      .mb {
        img { width: 28px; height: 28px; }
      }
    }
  }

  @include breakpoint(large) {
    .nav-login {
      font-size: px2rem(18);
    }
  }

  @include breakpoint(xlarge) {
    padding:0px; margin:0px auto; max-width:1380px;
  }
}

header {
    .hamburger {
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      color: $dark_blue;
      font-weight: 400;
      font-style: normal;
      font-family: 'Benton Sans',BentonSans,Arial,Helvetica,sans-serif;
      cursor: pointer;
      width: 30px;
      height: 30px;
      div {
        position: absolute;
        height: 2px;
        width: 30px;
        background: #002d73;
        display: block;
        content: " ";
        left: 0;
        top: 10px;
        transition: .5s transform ease-out;
        transition: .5s transform ease-out,.5s -webkit-transform ease-out;

        &:after {
          position: absolute;
          height: 2px;
          width: 30px;
          background: $dark_green;
          display: block;
          content: " ";
          left: 0;
          top: 10px;
          transition: .5s transform ease-out;
          transition: .5s transform ease-out,.5s -webkit-transform ease-out;
        }
      }
    }

  .hamburger-wrapper.active { 
    position:fixed;
    top:0px;
    left:0px;
    height:60px;
    width:100%;
    max-width: $max_mobile_nav_width;
    background:#FFFFFF;
    display:flex;
    align-items: center;
    padding:0px $sm-content-margin;
    z-index: 1;
    border-bottom:1px solid #d9d9d6;

    .hamburger div {
      transform: rotate(45deg) translate(2px,2px);
      &:after { transform: rotate(-90deg) translate(10px,0); top:10px;}
      &, &:after { transition: 0.5s transform ease-out; }
    }

    ~ nav.mainmenu {
      position:fixed;
      top:0px;
      left:0px;
      display:flex;
      background:rgba(0,0,0,0.5);
      display:block;
      width:100vw;
      height:100%;
      max-height: 100vh;
      overflow-y:hidden;
      ul { 
        height: auto; 
        margin-bottom: 0; 
        margin-left: 0; 
        //margin-top:60px;
        display:flex;
        flex-direction: column;
        background-color: #FFFFFF;
        height:100%;
        max-height: 100vh;
        overflow-y:auto;
        max-width:$max_mobile_nav_width;
        padding-top:60px;
      }

      li { 
        display: block;
        width:100%;
        height:60px;
        line-height: 60px;
        min-height: 60px;
        margin:0px;
        white-space: nowrap;
        .moreLink{display:none}

        &:first-child {
          a {
            border-top:none;
          }
        }
        &.hamburger-nav-login {
          height: unset;
          min-height: unset;
        }
        a { 
          display: block; 
          line-height: 60px;;
          top:0px;
          width:calc(100% - (#{$sm-content-margin} * 2));
          height:100%;
          border-top:1px solid #f7f7f7;
          margin:0px auto;
          text-decoration: none;
        }
        &.selected {
          border-left: solid 3px $dark_blue;
          a { 
            margin-left: 15px - 3px; 
            color:$dark_blue;
          }
        }
      }
      .hamburger-nav-login {
        li {
          a {
            display: flex;
          }
        }
      }
    }
  }

  nav.mainmenu {
    display:none;
  }

  @include breakpoint(medium) {
    .hamburger-wrapper {
      display:none;
    }

    nav.mainmenu, .subheader-nav-login {
      display:block;
      height:100%;
      padding:0px 12px;
      
      ul {
        display: flex;
        height: 100%;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        font-size:px2rem(16);
        > li {
          white-space: nowrap;
          display: flex;
          align-items: center;
          height:100%;
          margin:0px 19px;
          list-style: none;
          &.selected { 
            color: $dark_blue;
            border-bottom:solid 2px $dark_blue;
          }

          a {
            line-height: normal;
            height:auto;
            border:none;
            position: relative;
            top: 8px;
            text-decoration: none;
            cursor: pointer;
            color: #002d73;
          }
        }

        #home {
          display:none;
        }
        #more {
          display:flex;
          .moreLink {
            position: relative;
            top:8px;
            color: $dark_blue;
            cursor:pointer;
            &:hover { color: $selected_color; }
          }
        }
      }

      .submenu { 
          display: none; 
          position: absolute; 
          top: $nav_height; 
          left: -19px;
          width: 245px; 
          @include chromescrollfix; background-color:$white; border-top: 1px solid $gray_f;
          
          &.active { display: block; @include dd-box-shadow; }

          ul { 
            height: auto; 
            margin-bottom: 0; 
            margin-left: 0; 
            display:flex;
            flex-direction: column;
            //padding:0px 19px;
          }

          li { 
            display: block;
            width:100%;
            height:60px;
            line-height: 60px;;
            margin:0px;
            &:first-child {
              a {
                border-top:none;
              }
              
            }
            a { 
              display: block; 
              line-height: 60px;;
              top:0px;
              width:calc(100% - 38px);
              height:100%;
              border-top:1px solid #f7f7f7;
              margin:0px auto;
            }
            &.selected {
             border-left: solid 3px $dark_blue;
              border-bottom:none;
              a { 
                margin-left: 15px - 3px; 
                color:$dark_blue;
              }
            }
          }
      }
    }

    .subheader-nav-login {
      display: flex;

      .submenu {
        // width: 375px;

        li {
          font-size: px2rem(14);

          a {
            border-top: 1px solid $gray_e;
          }
          &:nth-child(2) a {
            border-top: 0;
          }
          &.name-avatar {
            font-size: px2rem(21);
            color: $dark_blue;
            @include tiempos(medium);
            // display: block; 
            line-height: 60px;;
            top: 0px;
            width: calc(100% - 38px);
            height: 100%;
            margin: 0px auto;
          }
        }
      }
    }

    .navigation_down_arrow { position: relative;
        &:after { position: absolute; top: calc(50% + 6px); right: -18px;
            @include border-arrow(10px, 5px, $selected_color, bottom);
            transition: 0.15s transform ease-in-out;
        }
        &.gray_8 {
            &:after { @include border-arrow(10px, 5px, $gray_6, bottom); }
        }

        .active &, &.active, .open &, &.open {
            &:after { transform: rotate(180deg); transition: 0.15s transform ease-in-out; }
        }
    }
  }

  @include breakpoint(large) {
      nav.mainmenu {
        ul {
          font-size: px2rem(18);
        }
      }
      .navigation_down_arrow {
        &:after {
          top: calc(50% + 4px);
        }
      }
  }

}