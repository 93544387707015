body {
    overflow-x: hidden;
    @include bentonsans;

    -webkit-text-size-adjust: none; /* Prevent font scaling in landscape while allowing user zoom */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; // http://jaydenseric.com/blog/css-font-smoothing-for-firefox-mac
}


// set global link color
a, a:visited {
    color: $dark_blue;
}

    /********

        *** At the very least, all pages should have this layout ***
        note:  you can have as many page-content sections necessary for your page.

        example 1:
        <Template>
            <section class="page-content>
            .... add your section content
            </section>
        </Template>

        example 2:
        <Template>
            <section class="page-content hero">
            .... add your hero
            </section>

            <section class="page-content">
            .... add you page content
            </section>
        </Template>


        *** If your section has a background that extends to the browser edges try this layout ***
        <Template>
            <section class="page-content hero">
                .... add your hero
            </section>

            <section class="content-wrapper">   // add an additional class that sets a background color
                <section class="page-content">
                    ... add your content
                </section>
            </section>
        </Template>


        ***  If your section has a grid layout you can do something like this.  Any element with class ".page-grid-item" will
             position to the correct column ***

        <Template>
            <section class="page-content hero">
                .... add your hero
            </section>
            <section class="page-content">
                <section class="page-grid cols-2">
                    <div class="item"></div>
                    <div class="item"></div>
                </section>
                <section class="page-grid cols-3">
                    <div class="item"></div>
                    <div class="item"></div>
                </section>
            </section>
        </Template>
    **********/

section {
    // use page-content for every section on your page.
    &.page-content {
        position: static;
        width:100%;
        max-width:$max-content-width;
        margin:0px auto;
        margin-top:$mobile-header-height;
       // height:100vh;
        &.loading {
            min-height: 400px; //amb-100918: loading indicator blank page
        }
        &:not(:first-child) {
            margin-top:0px;
        }
        &.hero {
			max-width: $max-content-width;
		}
        .home & {
            max-width: none;
        }
        &.notfound {
            img {
                width:100%;
            }
        }
    }

    &.content-wrapper {
        padding-bottom:$sm-content-margin;
        &.home {
            background-color: $white;
        }

        &.subpage {
            background-color: $page-content-bg;
        }
        > .page-content {
            margin-top:0px;
        }
    }

    &.page-grid {
        position: relative;
        display:flex;
        width:100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding:0px $xs-content-margin;
        margin-bottom:$sm-content-margin;
        max-width: 992px;
        width:92%;
        margin:0px auto;
        .page-grid-item {
            width:100%; min-height:50px;
            &.mockup {
                background-color:gray;
            }
        }
        &.cols-1 {
            .page-grid-item {
                width:100%;
                margin-top:$one-col-gutter-width-sm;
            }
        }
        &.cols-2 {
            .page-grid-item {
                width:100%;
                margin-top:$two-col-gutter-width-sm;
            }
        }
        &.cols-3 {
            .page-grid-item {
                width:100%;
                margin-top:$three-col-gutter-width-sm;
            }
        }
        &.cols-4 {
            .page-grid-item {
                width:100%;
                margin-top:$four-col-gutter-width-sm;
            }
        }

        // i don't think we need this
        // .stepSplash, .stepsOverview {
        //   .progressWrapper {
        //     .stepContainer:not(:last-child).active > .circleWrapper::after{
        //       background-color: $step-circle-color;;
        //     }
        //     .stepContainer:not(:last-child).completed > .circleWrapper::after{
        //       background-color: $step-circle-color;;
        //     }
        //     .stepContainer {
        //       .jsx-parser {
        //         ul li::before {
        //           content: counter(li);
        //           background-color: $step-circle-color;;
        //         }
        //         ul li::after {
        //           background-color: $step-circle-color;;
        //         }
        //       }
        //     }
        //     .active .circle {
        //       color: $step-circle-color;;
        //       border-color: $step-circle-color;;
        //     }
        //     .completed .circle {
        //       background-color: $step-circle-color;;
        //       border-color: $step-circle-color;
        //     }
        //   }
        // }

        // //section.page-grid .stepSplash .progressWrapper .stepContainer .jsx-parser ul li::before, section.page-grid .stepsOverview .progressWrapper .stepContainer .jsx-parser ul li::before
        // .stepSplash {
        //     &[data-step="3"] {
        //         .progressWrapper .stepContainer .jsx-parser ul li:nth-child(3) {
        //           &::before {
        //             content: counter(li);
        //             color: $step-circle-color;
        //             background-color: #FFFFFF;
        //           }
        //         }
        //       }
        // }
        
    }

    @include breakpoint(small) {
        &.page-grid {
            padding:0px $sm-content-margin;
            &.cols-2 {
                .page-grid-item {
                    width:calc(50% - (#{$two-col-gutter-width-sm} / 2));
                    margin-right:$two-col-gutter-width-sm;

                    @supports (-ms-ime-align:auto) {
                        margin-right:$two-col-gutter-width-sm-ie11;
                    }

                    &:nth-child(2n) {
                        margin-right:0px;
                    }
                }
            }
            &.cols-3 {
                .page-grid-item {
                    width:calc(50% - (#{$three-col-gutter-width-sm} / 2));
                    margin-right:$three-col-gutter-width-sm;

                    @supports (-ms-ime-align:auto) {
                        margin-right:$three-col-gutter-width-sm-ie11;
                    }

                    &:nth-child(2n) {
                        margin-right:0px;
                    }
                }
            }
            &.cols-4 {
                .page-grid-item {
                    //width:calc(25% - (#{$four-col-gutter-width-sm} / 3));
                    width:calc((100% / 4) - ((#{$four-col-gutter-width-sm} / 4) * 3));
                    margin-right:$four-col-gutter-width-sm;
                    &:nth-child(2n) {
                        margin-right:$four-col-gutter-width-sm;
                    }
                    &:nth-child(3n) {
                        margin-right:$four-col-gutter-width-sm;
                    }
                    &:nth-child(4n) {
                        margin-right:0px;
                    }
                }
            }
        }
    }
    @include breakpoint(medium) {
		&.page-content {
	        position: static;
	        width:100%;
            height:100%;
	        max-width:$max-content-width;
	        margin:0px auto;
	        margin-top:$mobile-header-height;

	        &.loading {
	            min-height: 400px; //amb-100918: loading indicator blank page
	        }
	        &:not(:first-child) {
	            margin-top:0px;
            }
            &.hero {max-width: $max-content-width;}

            .home & {
                max-width: none;
            }
	    }

        &.page-grid {
            padding:0px $lg-content-margin;
            margin-bottom: $sm-content-margin;
            &.cols-2 {
                margin-top:$two-col-gutter-width-lg;
                margin-right:$two-col-gutter-width-lg;
            }
            &.cols-3 {
                .page-grid-item {
                    width:calc((100% / 3) - ((#{$three-col-gutter-width-lg} / 3) * 2));
                    margin-top:$three-col-gutter-width-lg;
                    margin-right:$three-col-gutter-width-lg;

                    @supports (-ms-ime-align:auto) {
                        margin-right:$three-col-gutter-width-lg-ie11;
                    }

                    &:nth-child(2n) {
                        margin-right:$three-col-gutter-width-lg;

                        @supports (-ms-ime-align:auto) {
                            margin-right:$three-col-gutter-width-lg-ie11;
                        }

                    }
                    &:nth-child(3n) {
                        margin-right:0px;
                    }
                }
            }

            &.cols-4 {
                .page-grid-item {
                    width:calc((100% / 4) - ((#{$four-col-gutter-width-lg} / 4) * 3));
                    margin-top:30px;
                    margin-right:$four-col-gutter-width-lg;
                    &:nth-child(2n) {
                        margin-right:$four-col-gutter-width-lg;
                    }
                    &:nth-child(3n) {
                        margin-right:$four-col-gutter-width-lg;
                    }
                    &:nth-child(4n) {
                        margin-right:0px;
                    }
                }
            }
        }
    }
}

header.alert + section.main {
	.page-content.hero {
		margin-top:$mobile-header-height + $global-alert-height;

		@include breakpoint(medium) {
			margin-top:$medium-header-height + $global-alert-height;
		}
	}
}


/*
    sometimes we need to expand the container to full windowsize
    inside of limited width parent container.
    (e.g. Player Bio section expands to full window size with
    gray background-color)

    .full-window-width class allows us to expand the container
*/
.full-window-width {
    background-color: $page-content-bg;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    // set max-width and margin auto to the sides
    section {
        &.content-wrapper {
            max-width:$max-content-width;
            margin-left: auto;
            margin-right: auto;
        }

        &.page-grid {
            max-width:$max-content-width;
            margin-left: auto;
            margin-right: auto;
        }
    }

    section.section-title-wrapper {
        margin-left: auto;
        margin-right: auto;
        max-width:$max-content-width;
    }
} // ends fullWindowWidth

// when the alert is on
// awt trying to fix patron info
section.alert_on {
    section {
        &.page-content {
            margin-top: calc(#{$mobile-header-height} + #{$global-alert-height});

            @include breakpoint(medium) {
                margin-top: $header-height-alert;
            }

            &:not(:first-child) {
                margin-top:0px!important;
            }
        }

        &.content-wrapper {
            > .page-content {
                margin-top:0px;
            }
        }
    }
}